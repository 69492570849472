import { setContext } from '@sentry/nextjs';
import ApiInterface from '../app/modules/ApiInterface';
import Helper from '../app/modules/Helper';
import store from '../store';

import {
	selectFromDate as selectFromDateFlights,
	selectFromLocation as selectFromLocationFlight,
	selectPricingType,
	selectToDate as selectToDateFlights,
	selectTripType,
	setCountAdults,
	setCountChildren,
} from './flightActions';
import {
	AGREE_COOKIE_NOTICE,
	CAR_SELECT_FROM_LOCATION,
	CAR_SELECT_TO_LOCATION,
	ENABLE_VERT,
	FLIGHT_SELECT_FROM_LOCATION,
	FLIGHT_SELECT_TO_LOCATION,
	OPEN_CHILD_INPUT,
	OPEN_INPUT,
	PASSTHRU_SET_FOUND_PARTNER,
	PASSTHRU_SET_FOUND_PARTNER_LINK,
	PASSTHRU_TOGGLE_REDIRECT_IMMEDIATELY,
	PRICE_ALERT_SET_IS_CLOSED_SIGN_UP_MODAL,
	PRICE_ALERT_SHOW_MODAL_SIGN_UP,
	REMOVE_ERRORS,
	SET_COUNTRY_CODE,
	SET_DURATION_DETECT_REDIRECT_LINK,
	SET_IS_BROWSER_SUPPORT_NOTIFICATION,
	SET_LANGUAGE,
	SET_PHONE_NUMBER,
	SET_PRICE_ALERT,
	SET_PRICE_ALERT_EMAIL,
	SET_PRICE_ALERT_PHONE,
	SET_PUSH_SUBSCRIPTION,
	SET_TICKET_DATA,
	SET_TOOLTIP_ERROR,
	SET_UID_IMID,
	SET_VAPID_KEY,
	TOGGLE_AUTO_JUMP,
	TOGGLE_PREVENT_ENTER,
	TOGGLE_PREVENT_FOCUS,
	TOGGLE_PRICE_ALERT_HANDLER,
	TOGGLE_PROGRESS_LOADING,
	TOGGLE_SHOW_ERRORS,
	TOGGLE_SHOW_PHONE_NUMBER,
} from './types';

import {
	selectFromDate as selectFromDateCars,
	selectFromTime,
	selectToDate as selectToDateCars,
	selectToTime,
	toggleEnableReturnLocation,
} from './carActions';

import Cookies from 'js-cookie';
import { catchErrorSync } from '../app/components/Common/AppErrorBoundary';
import { parseFormData, submitHiddenForm } from './baseActions.utils';

import { pricingTypes, tripTypes } from 'app/modules/Constants';
import { format, setDefaultOptions } from 'date-fns';

export const openInput = (inputId) => {
	return catchErrorSync((dispatch) => {
		const w = window.innerWidth;

		if (inputId && w < 700) {
			Helper.doScrolling('#' + inputId + '-parent', 500);
		}

		if (!inputId) {
			store.dispatch(toggleAutoJump(false));
		}

		dispatch({
			type: OPEN_INPUT,
			payload: inputId,
		});
	});
};

export const openChildInput = (inputId) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: OPEN_CHILD_INPUT,
			payload: inputId,
		});
	});

export const setTooltipError = (errors) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_TOOLTIP_ERROR,
			payload: errors,
		});
	});

export const removeErrors = () =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: REMOVE_ERRORS,
		});
	});

export const toggleShowErrors = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: TOGGLE_SHOW_ERRORS,
			payload: toggle,
		});
	});

export const hideAndShowErrors = (element = '#filter-form-wrapper') =>
	catchErrorSync(() => {
		const w = parseInt(window.innerWidth);
		if (w < 1200) {
			Helper.doScrolling(element, 500);
		}
	});

export const setLocationByIATACode = (IATA, type) =>
	catchErrorSync((dispatch) => {
		ApiInterface.instance
			.fetchAirport(IATA, {
				lng: store.getState().base.lng,
			})
			.then((res) => {
				const airport = res.data;
				if (!Helper.isEmpty(airport)) {
					dispatch({
						type,
						payload: airport,
					});
				}
			})
			.catch((e) => {
				console.log('error fetching airport', e);
			});
	});

export const setCityByCode = (code, type) =>
	catchErrorSync((dispatch) => {
		if (!code) {
			return;
		}
		ApiInterface.instance
			.fetchCity(code, {
				lng: store.getState().base.lng,
			})
			.then((res) => {
				if (!Helper.isNumeric(code)) {
					if (res.data.results.length) {
						dispatch({
							type,
							payload: Helper.convertElasticCityResultItemToAirportItem(res.data.results[0]),
						});
					}
				} else {
					const airport = res.data;
					if (!Helper.isEmpty(airport)) {
						dispatch({
							type,
							payload: airport,
						});
					}
				}
			})
			.catch((e) => {
				console.log('error fetching city', e);
			});
	});

export const enableVert = (vert) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: ENABLE_VERT,
			payload: vert,
		});
	});

export const toggleProgressLoading = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: TOGGLE_PROGRESS_LOADING,
			payload: toggle,
		});
	});

export const toggleAutoJump = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: TOGGLE_AUTO_JUMP,
			payload: toggle,
		});
	});

export const agreeCookieNotice = () =>
	catchErrorSync((dispatch) => {
		try {
			localStorage.setItem('cookieNotice', true);
		} catch (error) {
			console.log('There was an error accessing LocalStorage', error);
		}
		dispatch({
			type: AGREE_COOKIE_NOTICE,
			payload: true,
		});
	});

// TODO: merge these two functions
export const sendDataToPassthruForm = (data, isTooLittleCompanies) =>
	catchErrorSync(() => {
		const queryStringData = store.dispatch(getQueryStringData());
		let formData = { ...queryStringData, ...data };

		if (isTooLittleCompanies) {
			// eslint-disable-next-line no-unused-vars
			const { fuid, buid, ...rest } = formData;
			formData = rest;
			window.location.href = '/passthru?' + Helper.serialize(formData);
			return;
		}
		window.open('/passthru?' + Helper.serialize(formData));
	});

export const sendDataToCompare = (data) =>
	catchErrorSync(() => {
		const queryStringData = store.dispatch(getQueryStringData());
		data = { ...data, ...queryStringData };
		window.location.href = `/${store.getState().base.enabledVert}/compare-select?${Helper.serialize(data)}`;
	});

export const init = (vert) => {
	return catchErrorSync(() => {
		try {
			const lngLocalStorage = localStorage.getItem('i18nextLng');

			if (lngLocalStorage && lngLocalStorage.length === 2) {
				store.dispatch(setLanguage(lngLocalStorage));
			}
		} catch (error) {
			console.log('There was an error accessing LocalStorage', error);
		}

		store.dispatch(isBrowserSupportNotification());
		store.dispatch(enableVert(vert));

		const parsed = Helper.parseQueryString(window.location.search);
		const lng = store.getState().base.lng;
		const theme = process.env.NEXT_PUBLIC_FRONT_SITE_THEME;
		ApiInterface.instance
			.init(vert, {
				...parsed,
				...{
					lng,
					site_theme: theme,
				},
			})
			.then((res) => {
				setContext('user', {
					id: res.data.uid,
					imid: res.data.imid,
				});
				store.dispatch(
					setUIDAndIMID({
						uid: res.data.uid,
						imid: res.data.imid,
					})
				);

				// const growthbookCallback = store.getState().growthbook.growthbookCallback;
				// if (growthbookCallback) {
				// 	growthbookCallback({
				// 		uid: res.data.uid,
				// 		imid: res.data.imid,
				// 		vert: store.getState().base.enabledVert,
				// 	});
				// }

				store.dispatch(setPriceAlert(res.data.priceAlert));
				store.dispatch(setVapidKey(res.data.vapidKey));

				const { closestAirport, userCountry } = res.data;

				if (userCountry) {
					store.dispatch(setCountryCode(userCountry));
					store.dispatch(checkToShowPhoneButton());
				} else {
					console.warn('No country detected :(');
				}

				if (Helper.hasProperty(res.data, 'closestAirport') && !Helper.isEmpty(closestAirport)) {
					if (lng === 'en') {
						switch (vert) {
							case 'flights':
								if (Helper.isEmpty(store.getState().flight.selectedFromLocation)) {
									store.dispatch(selectFromLocationFlight(closestAirport));
								}
								break;
							default:
								break;
						}
					}
				} else {
					console.warn('No airport found :(');
				}

				const { uid, imid } = res.data;
				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({
					event: 'facebookPixelInit',
					pixelExternalId: `${uid}${imid}`,
				});
			})
			.catch((e) => {
				console.log('init error', e);
			});
	});
};

export const passthruSetFoundPartnerLink = (partner) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: PASSTHRU_SET_FOUND_PARTNER_LINK,
			payload: partner,
		});
	});

export const passthruSetFoundPartner = (partner) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: PASSTHRU_SET_FOUND_PARTNER,
			payload: partner,
		});
	});

export const submitForm = (form, data) => {
	return catchErrorSync(() => {
		store.dispatch(saveSearchParametersInCookie(data));
		const redirectByClient =
			Helper.bool(process.env.NEXT_PUBLIC_REDIRECT_BY_CLIENT) || Helper.hasPT(2) || Helper.hasPT(3) || Helper.hasPT(4);
		const queryStringData = store.dispatch(getQueryStringData());
		const parsed = Helper.parseQueryString(window.location.search, true);
		const formData = { ...data, ...queryStringData };

		if (redirectByClient) {
			if (formData.tab === 'back') {
				if (queryStringData.f !== '0' && parsed.autoredirect !== '1') {
					store.dispatch(passthruToggleRedirectImmediately(true));
				}
			}

			const currentDate = new Date();
			const startTime = performance.now();

			ApiInterface.instance
				.clientRedirect(formData.vert, parseFormData(formData), {
					raxConfig: {
						retry: 1,
					},
				})
				.then((res) => {
					const endTime = performance.now();
					const responseTime = endTime - startTime;

					if (responseTime > 3000) {
						submitHiddenForm(formData, form);
					}

					store.dispatch(setDurationDetectRedirectLink(new Date().getTime() - currentDate.getTime()));
					store.dispatch(passthruSetFoundPartnerLink(res.data['redirect-url']));
					store.dispatch(passthruSetFoundPartner(res.data.partner));
				})
				.catch(() => {
					submitHiddenForm(formData, form);
				});
		} else {
			submitHiddenForm(formData, form);
		}
	});
};

export const getQueryStringData = () =>
	catchErrorSync(() => {
		const parsed = Helper.parseQueryString(window.location.search, true);
		const formData = {};
		if (parsed.country && parsed.country !== '') {
			formData['country'] = parsed['country'];
		}
		if (parsed['f'] && parsed['f'] !== '') {
			formData['f'] = parsed['f'];
		}
		if (parsed['fuid'] && parsed['fuid'] !== '') {
			formData['fuid'] = parsed['fuid'];
		}
		if (parsed['f-url'] && parsed['f-url'] !== '') {
			formData['f-url'] = parsed['f-url'];
		}
		if (parsed['b'] && parsed['b'] !== '') {
			formData['b'] = parsed['b'];
		}
		if (parsed['buid'] && parsed['buid'] !== '') {
			formData['buid'] = parsed['buid'];
		}
		if (parsed['b-url'] && parsed['b-url'] !== '') {
			formData['b-url'] = parsed['b-url'];
		}
		if (parsed['pt'] && parsed['pt'] !== '') {
			formData['pt'] = parsed['pt'];
		}
		if (parsed['lng'] && parsed['lng'] !== '') {
			formData['lng'] = parsed['lng'];
		}
		if (parsed['al'] && parsed['al'] !== '') {
			formData['al'] = parsed['al'];
		}
		if (parsed['bvert'] && parsed['bvert'] !== '') {
			formData['bvert'] = parsed['bvert'];
		}
		if (parsed['hchain'] && parsed['hchain'] !== '') {
			formData['hchain'] = parsed['hchain'];
		}
		if (parsed['plcid'] && parsed['plcid'] !== '') {
			formData['plcid'] = parsed['plcid'];
		}
		if (parsed['bplcid'] && parsed['bplcid'] !== '') {
			formData['bplcid'] = parsed['bplcid'];
		}
		const enabledVert = store.getState().base.enabledVert;
		switch (enabledVert) {
			case 'cars':
				if (parsed['car-class'] && (parsed['car-class'] === 'van' || parsed['car-class'] === 'pickup')) {
					formData['car-class'] = parsed['car-class'];
				}
				if (parsed['caragency'] && parsed['caragency'] !== '') {
					formData['caragency'] = parsed['caragency'];
				}

				break;

			default:
				break;
		}

		return formData;
	});

export const passthruToggleRedirectImmediately = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: PASSTHRU_TOGGLE_REDIRECT_IMMEDIATELY,
			payload: toggle,
		});
	});

export const setCountryCode = (code) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_COUNTRY_CODE,
			payload: code,
		});
	});

export const saveSearchParametersInCookie = (data) =>
	catchErrorSync(() => {
		Cookies.set(`${data.vert}_search_data`, JSON.stringify(data), { expires: 7, path: '/' });
	});

export const checkCookieSearchData = () =>
	catchErrorSync(() => {
		const vert = store.getState().base.enabledVert;
		let searchData = Cookies.get(`${vert}_search_data`);
		if (!searchData) return;
		searchData = JSON.parse(searchData);
		const queryStringData = Helper.parseQueryString(window.location.search, true);

		switch (vert) {
			case 'flights':
				store.dispatch(setLocationByIATACode(searchData['origin-iata'], FLIGHT_SELECT_FROM_LOCATION));
				if (!Helper.hasProperty(queryStringData, 'destcode')) {
					store.dispatch(setLocationByIATACode(searchData['destination-iata'], FLIGHT_SELECT_TO_LOCATION));
				}
				const priceType = Helper.searchByValue(pricingTypes, searchData['cabin-class']);
				const countAdults = searchData['num-adults'];
				const countChildren = searchData['num-youth'];
				const departureTime = new Date(searchData['departure-time']);
				const returnTime = new Date(searchData['return-time']);
				const isAfterDepartureTime = Helper.isDateAfter(departureTime);
				store.dispatch(setCountAdults(countAdults));
				store.dispatch(setCountChildren(countChildren));
				if (!isAfterDepartureTime) {
					if (!Helper.hasProperty(queryStringData, 'date-from')) {
						store.dispatch(selectFromDateFlights(departureTime));
					}
					if (Helper.isValidDate(returnTime)) {
						if (!Helper.hasProperty(queryStringData, 'date-to')) {
							store.dispatch(selectToDateFlights(returnTime));
						}
					} else {
						store.dispatch(selectTripType(Helper.searchByValue(tripTypes, 'one-way')));
					}
				}
				if (!Helper.isEmpty(priceType) && !Helper.hasProperty(queryStringData, 'cabin')) {
					store.dispatch(selectPricingType(priceType));
				}
				break;
			case 'cars':
				const pickupDestinationKey = searchData['pickup-destination-key'];
				const pickupDestinationId = searchData['pickup-destination-id'];
				if (!Helper.hasProperty(queryStringData, 'destcode')) {
					switch (pickupDestinationKey) {
						case 'city':
							store.dispatch(setCityByCode(pickupDestinationId, CAR_SELECT_FROM_LOCATION));
							break;
						case 'airport':
							store.dispatch(setLocationByIATACode(pickupDestinationId, CAR_SELECT_FROM_LOCATION));
							break;
						default:
							break;
					}
				}

				const pickupDate = new Date(searchData['pickup-time']);
				const pickupTime = searchData['pickup-t'];
				const dropOffDate = new Date(searchData['drop-off-time']);
				const dropOffTime = searchData['drop-off-t'];
				const isAfterPickupDate = Helper.isDateAfter(pickupDate);
				if (!isAfterPickupDate) {
					if (!Helper.hasProperty(queryStringData, 'date-from')) {
						store.dispatch(selectFromDateCars(pickupDate));
					}
					if (!Helper.hasProperty(queryStringData, 'date-to')) {
						store.dispatch(selectToDateCars(dropOffDate));
					}
				}
				if (Helper.isValidTime(pickupTime) && !Helper.hasProperty(queryStringData, 'time-from')) {
					store.dispatch(selectFromTime(pickupTime));
				}
				if (Helper.isValidTime(dropOffTime) && !Helper.hasProperty(queryStringData, 'time-to')) {
					store.dispatch(selectToTime(dropOffTime));
				}
				if (Helper.hasProperty(searchData, 'drop-off-destination') && searchData['drop-off-destination']) {
					store.dispatch(toggleEnableReturnLocation(true));
					const dropOffDestinationKey = searchData['drop-off-destination-key'];
					const dropOffDestinationId = searchData['drop-off-destination-id'];
					switch (dropOffDestinationKey) {
						case 'city':
							store.dispatch(setCityByCode(dropOffDestinationId, CAR_SELECT_TO_LOCATION));
							break;
						case 'airport':
							store.dispatch(setLocationByIATACode(dropOffDestinationId, CAR_SELECT_TO_LOCATION));
							break;
						default:
							break;
					}
				}
				break;
			default:
				break;
		}
	});

export const emitGMTEvent = (vert) =>
	catchErrorSync(() => {
		// if (process.env.NEXT_PUBLIC_SHOW_PRODUCTION_SCRIPTS !== 'false')
		//     return;
		window.dataLayer = window.dataLayer || [];
		const dataCars = store.getState().car;
		const dataFlights = store.getState().flight;
		const dataHotels = store.getState().hotel;
		const dataCruises = store.getState().cruises;

		const n = dataCars.selectedFromLocation.city
			? dataCars.selectedFromLocation.city
			: dataCars.selectedFromLocation.name;
		switch (vert) {
			case 'cars':
				window.dataLayer.push({
					event: 'formCarSearchSubmission',
					eventCategory: 'formSubmission',
					eventAction: 'formCarSearch',
				});
				window.dataLayer.push({
					event: 'Search',
					items: [
						{
							content_type: 'destination',
							content_id:
								dataCars.selectedFromLocation.type === 'city'
									? dataCars.selectedFromLocation.cityID
									: dataCars.selectedFromLocation.iata,
							city: n,
							region: dataCars.selectedFromLocation.state,
							country: dataCars.selectedFromLocation.country,
							travel_start: format(new Date(dataCars.selectedFromDate), 'yyyy-MM-dd'),
							travel_end: format(new Date(dataCars.selectedToDate), 'yyyy-MM-dd'),
						},
					],
				});
				break;
			case 'flights':
				window.dataLayer.push({
					event: 'formFlightSearchSubmission',
					eventCategory: 'formSubmission',
					eventAction: 'formFlightSearch',
				});

				window.dataLayer.push({
					event: 'Search',
					items: [
						{
							content_type: 'flight',
							origin_airport: dataFlights.selectedFromLocation.iata,
							destination_airport: dataFlights.selectedToLocation.iata,
							departing_departure_date: format(new Date(dataFlights.selectedFromDate), 'yyyy-MM-dd'),
							returning_departure_date: format(new Date(dataFlights.selectedToDate), 'yyyy-MM-dd'),
							departing_arrival_date: format(new Date(dataFlights.selectedFromDate), 'yyyy-MM-dd'),
							returning_arrival_date: format(new Date(dataFlights.selectedToDate), 'yyyy-MM-dd'),
							num_adults: dataFlights.countAdults,
							num_children: dataFlights.countChildren,
							num_infants: dataFlights.countInfants,
							travel_class: dataFlights.selectedPricingType.value,
						},
					],
				});
				break;
			case 'hotels':
				window.dataLayer.push({
					event: 'formHotelSearchSubmission',
					eventCategory: 'formSubmission',
					eventAction: 'formHotelSearch',
				});
				window.dataLayer.push({
					event: 'Search',
					items: [
						{
							content_type: 'destination',
							content_id:
								dataHotels.hotelDestination.type === 'city'
									? dataHotels.hotelDestination.cityID
									: dataHotels.hotelDestination.iata,
							city: dataHotels.hotelDestination.city,
							region: dataHotels.hotelDestination.state,
							country: dataHotels.hotelDestination.country,

							travel_start: format(new Date(dataHotels.checkInDate), 'yyyy-MM-dd'),
							travel_end: format(new Date(dataHotels.checkOutDate), 'yyyy-MM-dd'),
						},
					],
				});
				break;
			case 'cruises':
				window.dataLayer.push({
					event: 'formCruiseSearchSubmission',
					eventCategory: 'formSubmission',
					eventAction: 'formCruiseSearch',
				});
				window.dataLayer.push({
					event: 'Search',
					items: [
						{
							content_type: 'destination',
							departure_months: dataCruises.departureDate,
							destination: dataCruises.cruiseDestination.value,
							num_adults: dataCruises.passengers.adults,
							num_children: dataCruises.passengers.children,
							duration: dataCruises.duration,
						},
					],
				});
				break;
			default:
				break;
		}
	});

export const setLanguage = (lng) => {
	return catchErrorSync((dispatch) => {
		setDefaultOptions({ locale: Helper.getLocaleByCode(lng) });
		dispatch({
			type: SET_LANGUAGE,
			payload: lng,
		});
	});
};

export const setVapidKey = (vapidKey) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_VAPID_KEY,
			payload: vapidKey,
		});
	});

export const setPriceAlert = (priceAlert) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_PRICE_ALERT,
			payload: priceAlert,
		});
	});

export const isBrowserSupportNotification = () =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_IS_BROWSER_SUPPORT_NOTIFICATION,
			payload: 'Notification' in window,
		});
	});

export const setPushSubscription = (pushSubscription) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_PUSH_SUBSCRIPTION,
			payload: pushSubscription,
		});
	});

export const setPriceAlertEmail = (email) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_PRICE_ALERT_EMAIL,
			payload: email,
		});
	});

export const setPriceAlertPhone = (phone) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_PRICE_ALERT_PHONE,
			payload: phone,
		});
	});

export const togglePreventEnter = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: TOGGLE_PREVENT_ENTER,
			payload: toggle,
		});
	});

export const togglePreventFocus = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: TOGGLE_PREVENT_FOCUS,
			payload: toggle,
		});
	});

export const toggleShowPhoneNumber = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: TOGGLE_SHOW_PHONE_NUMBER,
			payload: toggle,
		});
	});

export const setPhoneNumber = (phone) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_PHONE_NUMBER,
			payload: phone,
		});
		if (phone) {
			store.dispatch(toggleShowPhoneNumber(true));
		}
	});

export const checkToShowPhoneButton = () =>
	catchErrorSync(() => {
		const { countryCode, lng, enabledVert } = store.getState().base;
		const supportedCountryCodes = ['US', 'CA'];
		if (supportedCountryCodes.indexOf(countryCode) !== -1 || lng === 'es') {
			if (enabledVert === 'hotels') {
				// Hotels number
				//  store.dispatch(setPhoneNumber('855-329-5408'));
			} else if (enabledVert === 'flights') {
				// flights number
				if (lng === 'es') {
					store.dispatch(setPhoneNumber(Helper.trans('phoneNumbers.withoutPrefix')));
				} else {
					store.dispatch(setPhoneNumber('858 492-7796'));
				}
			}
		}
	});

export const setTicketData = (data) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_TICKET_DATA,
			payload: data,
		});
	});

export const setDurationDetectRedirectLink = (milliseconds) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_DURATION_DETECT_REDIRECT_LINK,
			payload: milliseconds,
		});
	});

export const togglePriceAlertHandler = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: TOGGLE_PRICE_ALERT_HANDLER,
			payload: toggle,
		});
	});

export const togglePriceAlertSignUpModal = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: PRICE_ALERT_SHOW_MODAL_SIGN_UP,
			payload: toggle,
		});
	});

export const setClosedPriceAlertSignUpModal = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: PRICE_ALERT_SET_IS_CLOSED_SIGN_UP_MODAL,
			payload: toggle,
		});
	});

export const jumpToInput = () =>
	catchErrorSync((dispatch) => {
		Helper.doScrolling('.navbar', 500);
		switch (store.getState().base.enabledVert) {
			case 'cars':
			case 'flights':
				dispatch(openInput('airport-from'));
				document.getElementById('airport-from-input')?.focus();
				// @ts-ignore
				document.getElementById('airport-from-input')?.select();
				break;
			case 'hotels':
				dispatch(openInput('hotel-destination'));
				document.getElementById('hotel-destination-input')?.focus();
				// @ts-ignore
				document.getElementById('hotel-destination-input')?.select();
				break;
			default:
				break;
		}
	});

export const setLocationByZip = (code, type) =>
	catchErrorSync((dispatch) => {
		ApiInterface.instance
			.fetchZipCode(code, {
				lng: store.getState().base.lng,
			})
			.then((res) => {
				const airport = res.data;
				if (!Helper.isEmpty(airport)) {
					dispatch({
						type,
						payload: airport,
					});
				}
			})
			.catch((e) => {
				console.log('error fetching zip code', e);
			});
	});

export const setUIDAndIMID = (data) => (dispatch) => {
	try {
		localStorage.setItem('tpdrops-uid', data.uid);
		localStorage.setItem('tpdrops-imid', data.imid);
	} catch (e) {
		console.log('localStorage is not available', e);
	}

	dispatch({
		type: SET_UID_IMID,
		payload: data,
	});
};
